// extracted by mini-css-extract-plugin
export var animateHeart = "login-module--animateHeart--iL1nA";
export var card__description = "login-module--card__description--Lrx1Y";
export var card__form = "login-module--card__form--NpVT2";
export var card__title = "login-module--card__title--Kz2mt";
export var form__link = "login-module--form__link--guJtx";
export var heart = "login-module--heart--s15Rw";
export var image = "login-module--image--SfrZX";
export var login = "login-module--login--Kd1Nr";
export var login__card = "login-module--login__card--I5ECx";
export var login__copyright = "login-module--login__copyright--4MqlE";
export var login__image = "login-module--login__image--C-BzO";
export var login__wrapper = "login-module--login__wrapper--vy8c2";